"use client";
import React from "react";
import Link from "next/link";

const locations = [
  {
    country: "INDIA",
    cities: [
      {
        name: "Shopware Development Company in Mumbai",
        link: "/locations/ecommerce-website-development-company-in-mumbai",
      },
      {
        name: "Web Design Andheri West",
        link: "/locations/web-design-company-in-andheri-west",
      },
      {
        name: "Web Design Malad",
        link: "/locations/malad-web-designers-and-developers",
      },
      { name: "Web Design Mumbai", link: "/locations/web-design-in-mumbai" },
      {
        name: "Web Design Versova",
        link: "/locations/web-design-services-in-versova",
      },
      { name: "SEO Expert in Mumbai", link: "/locations/seo-expert-in-mumbai" },
      {
        name: "Website Design Juhu Mumbai",
        link: "/locations/website-design-services-in-juhu-mumbai",
      },
      {
        name: "Website Design Lokhandwala",
        link: "/locations/website-design-lokhandwala",
      },
      { name: "Website Design India", link: "/locations/website-design-india" },
    ],
  },
  {
    country: "CANADA",
    cities: [
      {
        name: "Web Design Services in Canada",
        link: "/locations/website-maintenance-services-in-canada",
      },
      {
        name: "Web Design Toronto",
        link: "/locations/non-profit-website-designer-toronto",
      },
      {
        name: "Website Design Calgary",
        link: "/locations/website-design-services-in-calgary",
      },
      {
        name: "Website Design Oakville",
        link: "/locations/website-design-services-in-oakville",
      },
    ],
  },
  {
    country: "USA",
    cities: [
      {
        name: "Affordable Web Design Services Newcastle",
        link: "/locations/affordable-web-design-services-newcastle",
      },
      {
        name: "Atlanta Web Design and Development",
        link: "/locations/atlanta-web-design-and-development",
      },
      {
        name: "Corpus Christi Web Design Services",
        link: "/locations/corpus-christi-web-design-services",
      },
      { name: "Denver Web Design", link: "/locations/denver-web-design" },
      {
        name: "Digital Marketing Services for Small Businesses in North Carolina",
        link: "/locations/digital-marketing-services-for-small-businesses-in-north-carolina-usa",
      },
      { name: "Las Vegas Web Design", link: "/locations/las-vegas-web-design" },
      {
        name: "Los Angeles Web Design and Development",
        link: "/locations/los-angeles-web-design-and-development",
      },
      {
        name: "Minneapolis Web Design",
        link: "/locations/minneapolis-web-design",
      },
      { name: "Nashville Web Design", link: "/locations/nashville-web-design" },
      {
        name: "New Jersey Web Design and Development",
        link: "/locations/new-jersey-web-design-and-development",
      },
      {
        name: "Next.js and React Website Services in Delaware",
        link: "/locations/nextjs-and-react-website-services-in-delaware",
      },
      { name: "Ojai Web Design", link: "/locations/ojai-web-design" },
      {
        name: "Orange County Web Design",
        link: "/locations/orange-county-web-design",
      },
      { name: "Orlando Web Design", link: "/locations/orlando-web-design" },
      {
        name: "Pittsburgh Web Design",
        link: "/locations/pittsburgh-pa-web-design",
      },
      {
        name: "Sacramento Web Design",
        link: "/locations/sacramento-web-design",
      },
      { name: "San Diego Web Design", link: "/locations/san-diego-web-design" },
      {
        name: "Texas Website Design Services",
        link: "/locations/texas-website-design-services",
      },
      {
        name: "Tulsa Web Design and Development",
        link: "/locations/tulsa-web-design-and-development",
      },
      {
        name: "Watersedge Web Design",
        link: "/locations/watersedge-web-design",
      },
      {
        name: "Web Design Austin TX",
        link: "/locations/web-design-company-austin-tx",
      },
      {
        name: "Web Design Berkeley",
        link: "/locations/web-design-services-berkeley",
      },
      {
        name: "Web Design Fort Lauderdale",
        link: "/locations/web-design-fort-lauderdale",
      },
      {
        name: "Web Design Houston TX",
        link: "/locations/web-design-houston-tx",
      },
      {
        name: "Web Design Jacksonville",
        link: "/locations/web-design-jacksonville",
      },
      {
        name: "Web Design Raleigh",
        link: "/locations/web-page-design-raleigh",
      },
    ],
  },
  {
    country: "UK",
    cities: [
      { name: "Web Design Banbury", link: "/locations/web-design-banbury" },
      { name: "Web Design Blackpool", link: "/locations/web-design-blackpool" },
      {
        name: "Web Design Bournemouth",
        link: "/locations/web-design-bournemouth",
      },
      {
        name: "Web Design Dudley",
        link: "/locations/dudley-web-design-company",
      },
      { name: "Web Design Essex", link: "/locations/web-design-essex" },
      { name: "Web Design Glasgow", link: "/locations/web-design-glasgow" },
      { name: "Web Design Hampshire", link: "/locations/web-design-hampshire" },
      { name: "Web Design Leeds", link: "/locations/web-design-leeds" },
      {
        name: "Web Design London",
        link: "/locations/london-web-design-and-development",
      },
      { name: "Web Design Rochdale", link: "/locations/web-design-rochdale" },
    ],
  },
  {
    country: "AUSTRALIA",
    cities: [
      {
        name: "Web Design Canberra",
        link: "/locations/canberra-web-design-and-development",
      },
      {
        name: "Web Design Central Coast",
        link: "/locations/website-design-services-in-central-coast",
      },
      { name: "Web Design Sooke", link: "/locations/web-design-agency-sooke" },
    ],
  },
  {
    country: "OTHER",
    cities: [
      {
        name: "Web Design Singapore",
        link: "/locations/web-design-services-singapore",
      },
    ],
  },
];

const Locations = ({ country = "All" }) => {
  country = country.toUpperCase();

  // Filter locations based on the provided country
  let filteredLocations;
  let showCountryInList = false;

  if (!country || country === "ALL") {
    // Show all countries and limit cities to 10
    filteredLocations = locations.map((loc) => ({
      ...loc,
      cities: loc.cities.slice(0, 10),
    }));
    showCountryInList = true;
  } else {
    filteredLocations = locations.filter((loc) => loc.country === country);

    // If country not found, show all countries and limit cities to 10
    if (filteredLocations.length === 0) {
      filteredLocations = locations.map((loc) => ({
        ...loc,
        cities: loc.cities.slice(0, 10),
      }));
      showCountryInList = true;
    }
  }

  return (
    <section className="bg-neutral-700 py-7 md:py-10 lg:py-12">
      <div className="max-w-screen-xl mx-5 lg:mx-auto">
        <h3 className="text-center mb-8 lg:mb-10 text-color-orange-300 font-semibold">
          We love to provide services in the following cities
        </h3>
        <div
          className={`text-sm ${
            showCountryInList
              ? "grid grid-cols-1 md:grid-cols-5 md:gap-8 lg:gap-10"
              : ""
          }`}
        >
          {filteredLocations.map((location, index) => (
            <div key={index}>
              {/* Show country name only if the country is "All" */}
              {showCountryInList && (
                <h5 className="font-bold text-color-orange-600 text-lg">
                  {location.country}
                </h5>
              )}
              <ul
                className={`leading-relaxed ${
                  !showCountryInList
                    ? "grid grid-cols-1 md:grid-cols-5 md:gap-2"
                    : ""
                }`}
              >
                {location.cities.map((city, idx) => (
                  <li key={idx}>
                    <Link
                      href={city.link}
                      className="text-white text-sm hover:text-color-orange-700"
                    >
                      {city.name}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
        <div className="flex flex-col items-center justify-center mt-10">
          <Link
            href="/locations"
            className="text-white border-2 border-color-orange-300 py-2 px-6 rounded-lg"
          >
            More Locations
          </Link>
        </div>
      </div>
    </section>
  );
};

export default Locations;
