"use client";

const CallToBoost = ({
  title = "Call (+91 98212 12676) To Boost Conversions with Custom Web Design",
  phoneNumber = "+91 98212 12676",
  description = "Turn visitors into customers with a blazing fast website that drives sales. Let's grow your online business.",
  buttonText = "Get Started",
  buttonLink = "/contact-us",
}) => {
  return (
    <div>
      <section className="bg-color-aqua-700 py-8 md:py-16 lg:py-20">
        <div className="max-w-screen-xl mx-5 lg:mx-auto">
          <div className="grid grid-cols-1 md:grid-cols-1 gap-3 md:gap-6 lg:gap-10 items-center justify-between text-center">
            <div className="lg:col-span-2 font-bold text-white">
              <h4 className="font-semibold text-white">
                Call{" "}
                <span className="bg-color-yellow-600 py-2 px-6 rounded-lg text-color-yellow-900 break-keep my-6 mx-2 lg:my-0 block lg:inline-block">
                  {phoneNumber}
                </span>{" "}
                To Boost Conversions with Custom Web Design
              </h4>
              <p>{description}</p>
            </div>
            <div className="col-span-1">
              <div className="text-center">
                <a
                  href={buttonLink}
                  className="font-medium md:text-base lg:text-lg text-black hover:white bg-white hover:bg-yellow-700 border-2 border-sky-800 rounded-full px-10 py-2 md:px-15"
                >
                  {buttonText}
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default CallToBoost;
