"use client";
import Image from "next/image";
import { FaArrowAltCircleRight } from "react-icons/fa";

const PortfolioWebDesign = () => {
  return (
    <section className="py-12">
      <div className="max-w-screen-2xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center lg:text-left">
          <h2 className="text-3xl sm:text-4xl lg:text-5xl font-extrabold text-gray-900">
            Website Design{" "}
            <span className="text-color-orange-700">Portfolio</span>
          </h2>
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-5 mt-8">
          <div className="bg-neutral-200 p-6 rounded-lg shadow-md">
            <Image
              src="/images/portfolio/360.jpg"
              alt="360 Studio"
              className="object-cover"
              width={800}
              height={400}
            />
            {/* <button className="mt-4 p-2 bg-orange-500 text-white rounded-full">
              <FaArrowAltCircleRight />
            </button> */}
          </div>

          <div className="bg-color-orange-500 p-6 rounded-lg shadow-md">
            <Image
              src="/images/portfolio/amp.jpg"
              alt="Amp"
              className="object-cover"
              width={800}
              height={400}
            />
          </div>

          <div className="bg-color-orange-600 p-6 rounded-lg shadow-md ">
            <Image
              src="/images/portfolio/angelstar.jpg"
              alt="Angel Star"
              className="object-cover"
              width={800}
              height={400}
            />
          </div>

          <div className="bg-neutral-200 p-6 rounded-lg shadow-md">
            <Image
              src="/images/portfolio/aft.jpg"
              alt="Aspire Fitech"
              className="object-cover"
              width={800}
              height={400}
            />
          </div>
        </div>
        <div className="flex flex-col justify-center items-center h-20">
          <a
            href="/portfolio"
            className="bg-color-orange-600 text-white py-2 px-10"
          >
            More Portfolio
          </a>
        </div>
      </div>
    </section>
  );
};

export default PortfolioWebDesign;
