"use client";
import Link from "next/link";

const primaryColors = "bg-color-orange-500 text-neutral-800";
const secondaryColors = "bg-neutral-800 text-color-orange-400";
let currentColour = primaryColors;
const specificIndexes = [0, 3, 5, 7, 9];

const ServicesHome = ({
  title = "What Variety of Web Solutions Does",
  colouredTitle = "Our Web Designing Company Offer?",
  description = "What Variety of Web Solutions Does",
  imageSrc = "",
  imageAlt = "",
  items = [
    {
      title: "Web Design Services",
      description:
        "Do you want a beautiful, user-friendly website? We use WordPress's variety, Gatsby.js' speed, or Next.js' cutting-edge capabilities to turn your ideas into a website that looks great, functions great, and stands out online.",
      icon: "📱",
      bgColor: "bg-red-100",
    },
    {
      title: "Ecommerce Website Design",
      description:
        "Ecommerce Website Design helps you maximise online business profits. Our web development agency develops reliable, user-friendly Ecommerce solutions for all types of enterprises. Your Shopify, Shopware, or WooCommerce store may look better and sell more with us.",
      icon: "📊",
    },
    {
      title: "Web Development",
      description:
        "Our Web Development services boost your online visibility. We combine Laravel with React to create powerful, easy-to-use web apps. Our developers can create complicated online portals and interactive web apps with a great user experience and reliable performance.",
      icon: "🔍",
    },
    {
      title: "Mobile App Development",
      description:
        "Our graphic design services are a blank canvas waiting to be filled with your uniqueness and innovative ideas. We bring your ideas to life with eye-catching visuals that make your point. Our skilled designers create works that are both aesthetically appealing and cohesive,",
      icon: "📈",
    },
  ],
}) => {
  return (
    <section className="py-12 services-home">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-left">
        <h2 className="text-3xl lg:text-5xl font-extrabold text-left text-neutral-800">
          {title}
          <span className="block text-color-orange-400">{colouredTitle}</span>
        </h2>
        <p
          className="mt-4 text-lg text-left text-black"
          dangerouslySetInnerHTML={{ __html: description }}
        ></p>
        <div className="mt-10 hidden md:grid grid-cols-1 sm:grid-cols-2 gap-8 ">
          {items.map((service, index) => {
            if (
              index % 2 ||
              index === 1 ||
              index === 3 ||
              index === 5 ||
              index === 7 ||
              index === 9 ||
              index === 11 ||
              index === 13 ||
              index === 15 ||
              index === 17
            ) {
              if (currentColour === primaryColors) {
                currentColour = secondaryColors;
              } else {
                currentColour = primaryColors;
              }
            }

            return (
              <div
                key={index}
                className={`${currentColour} p-6 rounded-lg shadow-md flex items-start justify-start flex-col min-h-[24rem]`}
              >
                <div className="h-20 w-20 rounded-full bg-white text-4xl flex items-center justify-center">
                  {service.icon}
                </div>
                <h3
                  className={`mt-4 text-2xl leading-6 font-bold ${
                    service.textColor || ""
                  }`}
                >
                  {service.title}
                </h3>
                <p
                  className={`my-2 mb-5 text-base ${
                    service.paragraphColors || ""
                  }`}
                >
                  {service.description}
                </p>
                <p>
                  {service.link && (
                    <Link
                      href={service.link}
                      className={`text-base font-semibold py-1 px-4 hover:bg-color-red-500 ${
                        currentColour === primaryColors
                          ? secondaryColors
                          : primaryColors
                      }`}
                    >
                      Learn More
                    </Link>
                  )}
                </p>
              </div>
            );
          })}
        </div>
        <div className="mt-10 grid md:hidden grid-cols-1 sm:grid-cols-2 gap-8 ">
          {items.map((service, index) => {
            if (currentColour === primaryColors) {
              currentColour = secondaryColors;
            } else {
              currentColour = primaryColors;
            }

            return (
              <div
                key={index}
                className={`${currentColour} p-6 rounded-lg shadow-md flex items-start justify-start flex-col min-h-[24rem]`}
              >
                <div className="h-20 w-20 rounded-full bg-white text-4xl flex items-center justify-center">
                  {service.icon}
                </div>
                <h3
                  className={`mt-4 text-2xl leading-6 font-bold ${
                    service.textColor || ""
                  }`}
                >
                  {service.title}
                </h3>
                <p
                  className={`my-2 mb-5 text-base ${
                    service.paragraphColors || ""
                  }`}
                >
                  {service.description}
                </p>
                <p>
                  {service.link && (
                    <Link
                      href={service.link}
                      className={`text-base font-semibold py-1 px-4 hover:bg-color-red-500 ${
                        currentColour === primaryColors
                          ? secondaryColors
                          : primaryColors
                      }`}
                    >
                      Learn More
                    </Link>
                  )}
                </p>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default ServicesHome;
